import "./style.css";

import DisplayImage from "./DisplayImage";

function Hello() {
  return (
    <div className="centered">
      <DisplayImage />
      <h1 id="heading"> >_ hello react!</h1>
      <p>
        i'm just playing around <br /> with react and css! <br /> 💙
      </p>
    </div>
  );
}

export default Hello;
