import React from "react";
import ReactDOM from "react-dom";
import Hello from "./Greeting";

// const Hey = () => {
//   return React.createElement("h1", {}, "nothing");
// };

// ReactDOM.render(<Hey />, document.getElementById("root"));

ReactDOM.render(<Hello />, document.getElementById("root"));
