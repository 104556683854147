import "./style.css";
import dp from "./assets/images/dp.png";
import React from "react";

function DisplayImage() {
  return (
    <React.Fragment>
      <img src={dp} className="main-img" alt="dp" />
    </React.Fragment>
  );
}

export default DisplayImage;
